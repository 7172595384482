import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
    ScopeOfApplication,
    HowItWorksBusiness,
    Projects,
    Advantages,
    StagesOfWorkBusiness,
    Benefits
} from '../components/ServicesAndPrices'
import { FormOrder, Breadcrumbs } from '../components/Global'
// content
import YAMLGlobal from "../content/ua/global.yaml"
import YAMLService from "../content/ua/service.yaml"

const ServicesReservePage = ({location}) => {    
    const data = useStaticQuery(graphql`
        query {
            seo: directusPricesBusinessSeo {
                title_ua
                description_ua
            }
            firstscreen: allDirectusPricesFirstscreen {
                edges {
                    node {
                        business_description_ua
                        business_title_ua
                        business_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1600) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            scope: allDirectusPricesBusinessesSphere {
                edges {
                    node {
                        list_ua
                        title_ua
                        id
                    }
                }
            },
            advantages: allDirectusPricesBusinessAdvantage {
                edges {
                    node {
                        id
                        title_ua
                        description_ua
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
            },
            projects: allDirectusOurProject(limit: 6, filter: {show_on_business: {eq: true}, category: {slug: {eq: "stancii-dlya-biznesa"}}}, sort: {fields: id}) {
                edges {
                    node {
                        id
                        slug
                        title_ua
                        title_photo {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            benefits: allDirectusPricesBusinessCarousel {
                edges {
                    node {
                        id
                        title_ua
                        description_ua
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 600) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            stages: allDirectusPricesBusinessStep {
                edges {
                    node {
                        id
                        title_ua
                        description_ua
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 600) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    
    const serviceLocal = {
        langBase: "/ua",
        YAMLGlobal,
        YAMLContent: YAMLService
    }
    
    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/services-business',
            title: YAMLGlobal.menu.services.business
        }
    ]
    
    return(
        <Layout local={serviceLocal} location={location}>
            <SEO title={data.seo.title_ua} description={data.seo.description_ua} lang='ua' />
            <Breadcrumbs list={list}/>
            <FirstScreen content={{
                title: data.firstscreen.edges[0].node.business_title_ua,
                text: data.firstscreen.edges[0].node.business_description_ua,
                img: data.firstscreen.edges[0].node.business_image.localFile?.childImageSharp.fluid
            }} />
            <ScopeOfApplication scope={data.scope.edges}/>
            <HowItWorksBusiness/>
            <Advantages advantages={data.advantages.edges}/>
            <Projects projects={data.projects.edges}/>
            <StagesOfWorkBusiness stages={data.stages.edges} />
            <Benefits benefits={data.benefits.edges}/>
            <FormOrder wave="gray"/>
        </Layout>
    )
}

export default ServicesReservePage;